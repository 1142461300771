






































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getchat, GetChatDetail } from "@/request/yian";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    // 处理聊天数据
    this.chartData = [];
    this.data.messages.forEach((ele: any) => {
      let obj: any = ele;
      if (ele.question) {
        obj.type = "doctor";
        obj.text = ele.question;
      } else {
        obj.type = "patient";
        obj.text = ele.answer;
      }
      this.chartData.push(obj);
    });
    setTimeout(() => {
      var myVideo: any = document.getElementById("video");
      myVideo.playbackRate = 0.6; //调整视频播放速度
    }, 100);
  }
  private text: any = "";
  private chartData: any = [];
  private loading: any = false;
  private chat_messages: any = [];
  private current_chat_messages: any = [];
  private audioUrl: any = "";
  private resultRadio: any = "";
  private refresh() {
    setTimeout(() => {
      this.chartData = [];
      this.chat_messages = [];
      this.current_chat_messages = [];
      this.loading = false;
      this.audioUrl = "";
      this.resultRadio = "";
      this.text = "";
    }, 200);
  }
  private preview(url: any) {
    window.open(url);
  }
  private send() {
    if (!this.text) {
      return;
    }
    this.chartData.push({
      text: this.text,
      type: "doctor",
    });
    // 让滚动条滚到底部
    this.$nextTick(() => {
      let scrollElem: any = this.$refs.scrollDiv;
      scrollElem.scrollTo({
        top: scrollElem.scrollHeight,
        behavior: "smooth",
      });
    });
    const params: any = {
      medcase_id: this.data.medcase_id,
      question: this.text,
      chat_messages: this.current_chat_messages,
      chat_log_id: this.data.chat_log_id,
    };
    this.text = "";
    this.loading = true;
    Getchat(this, params).then((res: any) => {
      if (res.error_code === 0) {
        if (res.data.answer) {
          this.current_chat_messages = res.data.chat_messages;
          // 原始聊天记录只需要添加最后2条
          const d = JSON.parse(JSON.stringify(res.data.chat_messages));
          const arr: any = d.splice(-2);
          this.chat_messages = this.chat_messages.concat(arr);
          // 拦截如果没有音频，不需要切换视频
          if (!res.data.remote_audio_save_path) {
            setTimeout(() => {
              this.chartData.push({
                text: res.data.answer,
                type: "patient",
                dataType: res.data.type,
              });
              // 让滚动条滚到底部
              this.$nextTick(() => {
                let scrollElem: any = this.$refs.scrollDiv;
                scrollElem.scrollTo({
                  top: scrollElem.scrollHeight,
                  behavior: "smooth",
                });
              });
              this.$emit("refreshList");
            }, 2000);
            this.loading = false;
            return;
          }
          // 切换视频
          this.resultRadio = this.data["动态视频地址"];
          this.audioUrl = res.data["remote_audio_save_path"];
          this.$forceUpdate();
          setTimeout(() => {
            (this.$refs.audio as any).play();
          }, 100);

          setTimeout(() => {
            this.chartData.push({
              text: res.data.answer,
              type: "patient",
            });
            // 让滚动条滚到底部
            this.$nextTick(() => {
              let scrollElem: any = this.$refs.scrollDiv;
              scrollElem.scrollTo({
                top: scrollElem.scrollHeight,
                behavior: "smooth",
              });
            });
          }, 2000);
        } else {
          // 解决后端未返回报错，只返回{}导致的页面卡死
          if (this.chat_messages.length > 20) {
            this.chat_messages = this.chat_messages.splice(-20);
          }
          this.$message.warning("请再重复问一遍");
          this.loading = false;
          // 输入框自动聚焦
          setTimeout(() => {
            (this.$refs.myInput as any).focus();
          }, 200);
          this.$forceUpdate();
        }
      } else {
        this.loading = false;
        // 输入框自动聚焦
        setTimeout(() => {
          (this.$refs.myInput as any).focus();
        }, 200);
        this.$message.error(res.msg);
        this.$forceUpdate();
      }
    });
  }
  private ended() {
    this.resultRadio = "";
    this.loading = false;
    // 输入框自动聚焦
    setTimeout(() => {
      (this.$refs.myInput as any).focus();
    }, 200);
    this.$forceUpdate();
  }
}
