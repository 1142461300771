import { render, staticRenderFns } from "./jiaoxue-folder.vue?vue&type=template&id=322a40ff&scoped=true&"
import script from "./jiaoxue-folder.vue?vue&type=script&lang=ts&"
export * from "./jiaoxue-folder.vue?vue&type=script&lang=ts&"
import style0 from "./jiaoxue-folder.vue?vue&type=style&index=0&id=322a40ff&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "322a40ff",
  null
  
)

export default component.exports