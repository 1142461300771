


























































import { Component, Vue, Watch } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import LeftNav from "../../components/left-nav.vue";
import NavBox from "../../components/nav-box.vue";
import { AddChat, DelChat, GetChatDetail, GetChatList } from "@/request/yian";
import Chat from "../../components/chat.vue";
@Component({
  components: {
    MainHeader,
    Empty,
    LeftNav,
    NavBox,
    Chat,
  },
})
export default class Name extends Vue {
  @Watch("jibing", { immediate: true })
  private list: any = [];
  private current: any = {};
  private detail: any = {};
  private jibingChange() {
    if (this.jibing && this.$refs.topSearch) {
      (this.$refs.topSearch as any).reset();
    }
  }
  private get jibing() {
    return this.$store.state.jibing;
  }
  private refreshList() {
    // 如果当前的对话标题是新建对话需要刷新
    if (this.current.title == "新建对话") {
      this.getChatList();
    }
  }
  private addOne() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const ids: any = [];
    this.list.forEach((ele: any) => {
      ids.push(ele.medcase_id);
    });
    const params: any = {
      bingzhong_id: this.jibing.child.id,
      medcase_ids: ids,
    };
    AddChat(this, params, loading)
      .then((res: any) => {
        loading.close();
        // 选中新增的对话
        this.current = res.chat_log;
        this.getChatList();
      })
      .catch(() => {
        loading.close();
      });
  }
  private getChatList() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params:any={
      params:{
        bingzhong_id:this.jibing.child.id
      }
    }
    GetChatList(this,params, loading)
      .then((res: any) => {
        loading.close();
        this.list = res;
        if (this.list.length > 0) {
          if (!this.current.chat_log_id) {
            this.current = this.list[0];
          }
          this.getDetail();
        } else {
          this.current = {};
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  private getDetail() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        chat_log_id: this.current.chat_log_id,
      },
    };
    GetChatDetail(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.detail = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  private selectOne(item: any) {
    this.current = item;
    this.getDetail();
  }
  private delChat(items: any) {
    this.$confirm("确定要删除该对话么？", "删除", {
      customClass: "commonConfirm",
    }).then(() => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        data: {
          chat_log_id: items.chat_log_id,
        },
      };
      DelChat(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.$message.warning("删除成功");
          // 如果删除的是当前选中的，需要切换右边内容
          if (items.chat_log_id == this.current.chat_log_id) {
            this.current = {};
          }
          this.getChatList();
        })
        .catch(() => {
          loading.close();
        });
    });
  }
  private mounted() {
    this.getChatList();
  }
}
